const HAMBURGER_SVG = "M16 13v2H0v-2h16zm0-6v2H0V7h16zm0-6v2H0V1h16z";
const CROSS_SVG =
  "M14.7 1.3c-.4-.4-1-.4-1.4 0L8 6.6 2.7 1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L6.6 8l-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3L8 9.4l5.3 5.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L9.4 8l5.3-5.3c.4-.4.4-1 0-1.4z";
const wheelOpt = { passive: false };

var style;
var svg;

export const preventDefault = (e) => {
  e.preventDefault();
};

export const onInitialClientRender = () => {
  window.addEventListener("load", function () {
    window.AOS.init();
  });
};

export const onRouteUpdate = ({ location }) => {
  if (location.pathname.startsWith("/class_timetable.html")) {
    getNotification();
  }

  style = document.getElementById("header-content").style;
  svg = document.getElementById("hamburger-path");
  if (!svg) {
    return;
  }

  svg.setAttribute("d", HAMBURGER_SVG);
  window.menuClicked = menuClicked;
  window.removeEventListener("touchmove", preventDefault, wheelOpt);

  if (window.AOS && window.AOS.init) {
    window.AOS.init();
  }
};

function closeMenu() {
  style.display = "none";
  svg.setAttribute("d", HAMBURGER_SVG);
  window.removeEventListener("touchmove", preventDefault, wheelOpt);
}

function openMenu() {
  style.display = "block";
  svg.setAttribute("d", CROSS_SVG);
  window.addEventListener("touchmove", preventDefault, wheelOpt);
}

export const menuClicked = () => {
  if (style.display === "block") {
    closeMenu();
  } else {
    openMenu();
  }
};

function getNotificationElem() {
  return document.getElementById("notification");
}

function displayNotification() {
  const elem = getNotificationElem();
  const text = this.responseText;

  if (text != "") {
    elem.style.display = "block";

    elem.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
            </svg>
            <span id='notification-text' className={commonStyles.notificationText}>${text}</span>
        `;
  }
}

export const getNotification = () => {
  const elem = getNotificationElem();
  if (!elem || elem.childElementCount) {
    return;
  }
  const http = new XMLHttpRequest();
  const url =
    "https://ltgyrzoc4e.execute-api.eu-west-2.amazonaws.com/prod/notification";
  http.open("GET", url);
  http.addEventListener("load", displayNotification);
  http.send();
};
